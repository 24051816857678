/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Layout, SectionWithContent, StaticSEO } from '@core';
import { ColorContextProvider } from '@composite';
import { Dimensions } from '@types';
import { ScorePerBankChart } from 'src/components/composite/chart-generator/shared/components/score';

const dimensions: Dimensions = [
  {
    key: 'Webseite',
    value: '6.70',
  },
  {
    key: 'Onlinemarketing',
    value: '3.62',
  },
];

const Page = () => (
  <Layout invertedHeader>
    <StaticSEO pageId='blog' />
    <SectionWithContent>
      <ColorContextProvider>
        <ScorePerBankChart dimension={dimensions} />
      </ColorContextProvider>
    </SectionWithContent>
  </Layout>
);

export default Page;
