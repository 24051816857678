import { SxStyleProp } from 'theme-ui';

export const chartHeight: SxStyleProp = {
  height: '100%',
  my: 5,
  '.label': {
    justifyContent: 'left',
  },
  '.chart': {},
};
