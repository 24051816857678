/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import React from 'react';

import { Chart } from '../base';
import { chartHeight } from './ScorePerBankChart.styles';
import { SimpleLabelComponent } from './SimpleLabelComponent';
import { DimensionProp, SimpleChartComponent } from './SimpleChartComponent';

interface ScorePerChartProps extends DimensionProp {
  exportChartRef?: React.RefObject<HTMLDivElement>;
  exportLabelsRef?: React.RefObject<HTMLDivElement>;
}

export const ScorePerBankChart = ({ dimension, exportChartRef, exportLabelsRef }: ScorePerChartProps) => (
  <Flex variant='flex.col'>
    <Chart sx={chartHeight} ref={exportChartRef}>
      <SimpleLabelComponent dimension={dimension} ref={exportLabelsRef} />
      <SimpleChartComponent dimension={dimension} ref={exportChartRef} />
    </Chart>
  </Flex>
);
